define('ember-fullscreen/initializers/fullscreen', ['exports', 'ember', 'ember-fullscreen/services/fullscreen'], function (exports, _ember, _emberFullscreenServicesFullscreen) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports.initialize = initialize;

  var _Ember$VERSION$match$map = _ember['default'].VERSION.match(/^(\d+)\.(\d+)\.(\d+)/).map(Number);

  var _Ember$VERSION$match$map2 = _slicedToArray(_Ember$VERSION$match$map, 4);

  var MAJOR = _Ember$VERSION$match$map2[1];
  var MINOR = _Ember$VERSION$match$map2[2];
  var PATCH = _Ember$VERSION$match$map2[3];

  function initialize() {
    var application = MAJOR >= 2 ? arguments[0] : arguments[1];
    application.register('fullscreen:main', _emberFullscreenServicesFullscreen['default']);
    application.inject('controller', 'fullscreen', 'fullscreen:main');
    application.inject('component', 'fullscreen', 'fullscreen:main');
    application.inject('route', 'fullscreen', 'fullscreen:main');
  }

  exports['default'] = {
    name: 'fullscreen',
    initialize: initialize
  };
});