define('ember-cli-zero-clipboard/components/zero-clipboard', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  /* global ZeroClipboard */

  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['title', 'data-clipboard-text', 'data-clipboard-target'],
    title: 'Copy to clipboard',
    zeroClipboardEvents: ['ready', 'beforeCopy', 'copy', 'afterCopy', 'destroy', 'error'],

    didInsertElement: function didInsertElement() {
      var client = new ZeroClipboard(this.get('element'));
      var self = this;

      //bind Zero Clipboard events to ember events
      this.get('zeroClipboardEvents').forEach(function (action) {
        client.on(action, _ember['default'].run.bind(this, function (event) {
          try {
            if (this.attrs[action]) {
              this.attrs[action](event);
            } else {
              this.send(action, event);
            }
          } catch (error) {
            _ember['default'].Logger.debug(error.message);
          }
        }));
      }, self);
    },

    "data-clipboard-text": _ember['default'].computed('text', function () {
      return this.get('text');
    }),

    "data-clipboard-target": _ember['default'].computed('cbTarget', function () {
      return this.get('cbTarget');
    })
  });
});