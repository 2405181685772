define('ember-fullscreen/services/fullscreen', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  /* global screenfull */

  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    screenfull: screenfull,

    setupListeners: _ember['default'].on('init', function () {
      if (this.get('isAvailable')) {

        this.updateEnabled();
        this.handler = _ember['default'].run.bind(this, this.updateEnabled);
        this.errorHandler = _ember['default'].run.bind(this, this.onError);

        document.addEventListener(this.screenfull.raw.fullscreenchange, this.handler);
        document.addEventListener(this.screenfull.raw.fullscreenerror, this.errorHandler);
      }
    }),

    willDestroy: function willDestroy() {
      if (this.get('isAvailable')) {
        document.removeEventListener(this.screenfull.raw.fullscreenchange, this.handler);
        document.removeEventListener(this.screenfull.raw.fullscreenerror, this.errorHandler);
      }
    },

    isEnabled: false,

    isAvailable: _ember['default'].computed(function () {
      return this.screenfull.enabled;
    }),

    updateEnabled: function updateEnabled() {
      this.set('isEnabled', this.screenfull.isFullscreen);
    },

    onError: function onError(event) {
      this.trigger('error', event);
    },

    enable: function enable(elem) {
      this.screenfull.request(elem);
    },

    disable: function disable() {
      this.screenfull.exit();
    },

    toggle: function toggle(elem) {
      this.screenfull.toggle(elem);
    }

  });
});