define('ember-rl-dropdown/components/rl-dropdown', ['exports', 'ember', 'ember-rl-dropdown/components/rl-dropdown-container'], function (exports, _ember, _emberRlDropdownComponentsRlDropdownContainer) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['rl-dropdown'],

    dropdownContainer: _ember['default'].computed(function () {
      return this.nearestOfType(_emberRlDropdownComponentsRlDropdownContainer['default']);
    }),

    isExpanded: _ember['default'].computed.alias('dropdownContainer.dropdownExpanded'),

    closeOnChildClick: false,

    propagateClicks: true,

    manageVisibility: _ember['default'].on('didInsertElement', _ember['default'].observer('isExpanded', function () {
      if (this.get('isExpanded')) {
        this.$().css('display', 'block');
      } else {
        this.$().css('display', 'none');
      }
    })),

    click: function click(event) {
      var closeOnChildClick = this.get('closeOnChildClick');
      var propagateClicks = this.get('propagateClicks');
      var $target = _ember['default'].$(event.target);
      var $c = this.$();

      if ($target !== $c) {
        if ((closeOnChildClick === true || closeOnChildClick === "true") && $target.closest($c).length) {
          this.set('isExpanded', false);
        } else if (closeOnChildClick && $target.closest(closeOnChildClick, $c).length) {
          this.set('isExpanded', false);
        }
      }

      if (propagateClicks === false || propagateClicks === "false") {
        event.stopPropagation();
      }
    }
  });
});